<template>
  <div class="modal" tabindex="-1" :id="id" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-white">
        <div class="modal-header bg-light-grey text-bold text-dark-blue p-3">
          <span v-if="!editEmployee">Add employee</span>
          <span v-else>Edit employee</span>
          <modal-close-button @confirmed="closeModal" />
        </div>
        <div class="modal-body">
          <div class="container px-3 pb-3">
            <!-- <div class="row mb-2 ml-0">
              <div class="col-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="statusCheckbox"
                    :checked="employee.status === 'Active'"
                    @change="updateEmployeeStatus"
                  />
                  <label class="form-check-label" for="statusCheckbox">
                    {{ employee.status }}
                  </label>
                </div>
              </div>
            </div> -->

            <div class="row mb-2 ml-0">
              <div class="col-6">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Employee first name"
                    v-model="employee.firstname"
                  />
                  <label>Employee first name*</label>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Employee last name"
                    v-model="employee.lastname"
                  />
                  <label>Employee last name*</label>
                </div>
              </div>
            </div>

            <div class="row mb-2 ml-0">
              <div class="col-6">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Employee phone number"
                    v-model="employee.phone"
                  />
                  <label>Employee phone number</label>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Employee email address"
                    v-model="employee.email"
                  />
                  <label>Employee email address*</label>
                </div>
              </div>
            </div>

            <!-- <div class="row w-110-p">
              <div class="col-12">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Employee address"
                    v-model="employee.address"
                  />
                  <label>Employee address</label>
                </div>
              </div>
            </div> -->

            <div class="row mb-2 ml-0">
              <div class="col-6">
                <div class="form-group">
                  <v-select
                    class="w-100-p"
                    v-model="employee.role"
                    :options="roles"
                    label="name"
                    placeholder="Role"
                    :searchable="false"
                  ></v-select>
                  <label>Employee role*</label>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Employee position"
                    v-model="employee.position"
                  />
                  <label>Employee position</label>
                </div>
              </div>
              <div class="col-6 fs-10">*Mandatory</div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-12 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-sm btn-grey mr-3"
                data-dismiss="modal"
                @click="closeModal"
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-success"
                @click="saveEmployee"
                :disabled="!formIsValid"
              >
                <span v-if="!editEmployee">Send registration link</span>
                <span v-else>Edit employee</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
	
	<script>
import { modal } from "../../mixins/Modal";
import ModalCloseButton from "@/components/global/ModalCloseButton";

import { uuidv4 } from "@/utils/Uuid";

export default {
  name: "EmployeeModal",
  components: {
    ModalCloseButton,
  },
  props: {
    id: {
      type: String,
      default: "employee-modal",
    },
    editEmployee: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      employee: {
        id: null,
        firstname: null,
        lastname: null,
        company: {
          name: null,
          address: null,
          phone: null,
          email: null,
          website: null,
        },
        role: { name: null, description: null },
        position: null,
        email: null,
        phone: null,
        status: "Active",
      },
      defaultEmployee: {
        id: null,
        firstname: null,
        lastname: null,
        company: {
          name: null,
          address: null,
          phone: null,
          email: null,
          website: null,
        },
        role: { name: null, description: null },
        position: null,
        email: null,
        phone: null,
        status: "Active",
      },
      roles: [
        // {
        //   id: "1889B2E8093",
        //   name: "Administrator",
        //   description: "Role-Description",
        //   appkey: "admin",
        // },
        // {
        //   id: "188AEA750CF",
        //   name: "Manufacturer",
        //   description: "",
        //   appkey: "manufacturer",
        // },
        {
          id: "188AEA7CD44",
          name: "Product Developer",
          description: "",
          appkey: "product_developer",
        },
        {
          id: "188AEA80E7A",
          name: "Producer",
          description: "",
          appkey: "producer",
        },
      ],
    };
  },
  computed: {
    formIsValid() {
      if (
        this.employee.firstname &&
        this.employee.lastname &&
        this.employee.email &&
        this.employee.role?.id
      )
        return true;
      return false;
    },
  },
  mixins: [modal],
  mounted() {
    this.$bus.$on("open-modal", (id) => {
      if (id === "employee-modal") {
        this.$nextTick(() => {
          if (this.editEmployee) {
            this.employee = JSON.parse(JSON.stringify(this.editEmployee));
          } else {
            this.employee = JSON.parse(JSON.stringify(this.defaultEmployee));
          }
        });
      }
    });
  },
  methods: {
    saveEmployee() {
      this.$emit("addEmployee", this.employee);
      this.closeModal();
    },
    updateEmployeeStatus() {
      if (this.employee.status === "Active") {
        this.employee.status = "Inactive";
      } else {
        this.employee.status = "Active";
      }
    },
  },
};
</script>
	